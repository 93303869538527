import ideaIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/idea-icon.svg'
import styled from 'styled-components'
import { Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #2963a3;
`
export const Content = styled.div`
  padding: 124px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    padding: 99px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 83px 32px;
  }

  @media (max-width: ${size.xs}) {
    padding: 78px 16px;
  }
`

export const IdeaImage = styled.img.attrs(() => ({
  src: ideaIcon
}))`
  text-align: center;
  position: absolute;
  top: -51px;
  left: 50%;
  transform: translateX(-50%);
`

export const CardContainer = styled.div`
  width: 792px;
  padding: 40px 24px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;
  margin: 0 auto;
  position: relative;

  @media (max-width: ${size.md}) {
    width: 604px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const TextWrapper = styled(Text.Large)`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #333;
  text-align: center;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`
