import React from 'react'
import { CardOneImage, CardThreeImage, CardTwoImage } from './CardsBlock.styles'

export const cardData = [
  {
    title: 'Разделение с мыслями',
    text: 'Умение относиться к своим мыслям не как к истине и неизбежной реальности, а как к словам, почему-то возникшим в нашей голове и создающим назойливый шум.',
    image: <CardOneImage />
  },
  {
    title: 'Принятие чувств',
    text: 'Навык концентрироваться на ощущениях в теле, когда испытываем эмоциональный дискомфорт, для того, чтобы пережить их.',
    image: <CardTwoImage />
  },
  {
    title: 'Концентрация внимания на здесь и сейчас',
    text: 'Умение переключаться в позицию внутреннего наблюдателя, отслеживать избегающие действия.',
    image: <CardThreeImage />
  }
]
