import React from 'react'
import {
  Card,
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardSevenImage,
  CardSixImage,
  CardThreeImage,
  CardTwoImage,
  CardsContainer,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TitleWrapper,
  Wrapper
} from './QueriesACT.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

const cardData = [
  {
    image: <CardOneImage />,
    text: 'Улучшить качество своей жизни'
  },
  {
    image: <CardTwoImage />,
    text: 'Наладить отношения с окружающими'
  },
  {
    image: <CardThreeImage />,
    text: 'Справиться с тревогой, самокритикой, неуверенностью или агрессией'
  },
  {
    image: <CardFourImage />,
    text: 'Совладать с хроническими болями, навязчивостями, страхами'
  },
  {
    image: <CardFiveImage />,
    text: 'Справиться с депрессией'
  },
  {
    image: <CardSixImage />,
    text: 'Пережить утрату'
  },
  {
    image: <CardSevenImage />,
    text: 'Совладать с последствиями психологической травмы'
  }
]

export const QueriesACT = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Запросы, с которыми работает специалист</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Терапия принятия и ответственности охватывает достаточно широкий
                круг запросов. Например:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <Text.Large semiBold>{card.text}</Text.Large>
              </Card>
            ))}
          </CardsContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
