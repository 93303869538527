import React from 'react'
import {
  BlocknotImage,
  CardContainer,
  Content,
  TextWrapper,
  Wrapper
} from './HomeWork.styles'

export const HomeWork = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <BlocknotImage />
          <TextWrapper>
            Иногда специалист будет предлагать домашние задания, и это только по
            активному согласию и желанию. Более того, любая практика выполняется
            тогда, когда клиент согласен и выражает готовность попробовать
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
