import React from 'react'
import {
  BlockImage,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourSpecialist.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'
import { TitleWrapper } from '../Common.styles'

export const FindYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите психотерапевта для консультации прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                От подходящего психолога вас отделяет одна ссылка и несколько
                минут. Укажите ваш запрос и пожелания к психологу, а алгоритм
                подберет вам специалиста по терапии принятия и ответственности
                или в любом из 30 других подходов, по вашему желанию.
              </Text.Large>
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Подберите психолога и запишитесь на консультацию прямо сейчас!" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
