import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  Wrapper
} from './ACTPurpose.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'

export const ACTPurpose = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="ACT концентрируется на важных действиях" />
          </ImageWrapper>
          <DescriptionWrapper>
            <Text.Large bold>
              ACT, как и гласит её название, концентрируется на важных
              действиях, которые хочется добавить или убрать из своей жизни,
              чтобы сделать её более осмысленной. Порой мы попадаем в неприятные
              ситуации и действуем «на поводу» у наших мыслей или эмоций.
            </Text.Large>
            <Text.Large semiBold>
              Задача мозга – уберечь нас от опасности, даже если она иллюзорна.
              Вместе с психологом мы выбираем действия, которые хотели бы делать
              вместо тех, на которые нас толкает тревога. То есть осознанные
              действия, имеющие смысл, ориентированные на наши ценности. Далее
              специалист по терапии и принятию ответственности поможет обратить
              внимание на препятствия в виде мыслей и чувств и натренировать
              навыки справляться с ними.
            </Text.Large>
          </DescriptionWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
