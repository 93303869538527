import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './HowToFindAndChoose.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const HowToFindAndChoose = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="Как найти и выбрать психолога для терапии" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Как найти и выбрать
                <br /> психолога для терапии
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Подобрать терапевта самостоятельно бывает непросто. Важно, чтобы
                помимо обучения терапии принятия и ответственности, психолог
                обладал высшим психологическим образованием, ходил на личную
                терапию и супервизии, придерживался высоких этических
                стандартов. Кроме того, у каждого психолога есть темы, в которых
                он силен, и темы, с которыми он не работает.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
