import React from 'react'
import {
  CardContainer,
  Content,
  RuporImage,
  TextWrapper,
  TitleSection,
  Wrapper
} from './WhatIsACT.styles'

export const WhatIsACT = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <RuporImage />
          <TitleSection>
            Что такое терапия принятия и ответственности
            <br /> и как она работает
          </TitleSection>
          <TextWrapper>
            Терапия принятия и ответственности (она же Acceptance and Commitment
            Therapy или ACT) была создана с целью помочь людям принять то, что
            находится вне их личного контроля и совершать действия, которые
            улучшат жизнь.
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
