import cardFiveIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-5.svg'
import cardFourIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-4.svg'
import cardOneIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-1.svg'
import cardSevenIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-7.svg'
import cardSixIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-6.svg'
import cardThreeIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-3.svg'
import cardTwoIcon from '../../../../static/img/ActTerapiyaPrinyatiyaIOtvetstvennosti/queriesACT-2.svg'
import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const CardThreeImage = styled.img.attrs(() => ({
  src: cardThreeIcon
}))``

export const CardFourImage = styled.img.attrs(() => ({
  src: cardFourIcon
}))``

export const CardFiveImage = styled.img.attrs(() => ({
  src: cardFiveIcon
}))``

export const CardSixImage = styled.img.attrs(() => ({
  src: cardSixIcon
}))``

export const CardSevenImage = styled.img.attrs(() => ({
  src: cardSevenIcon
}))``

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};
  max-width: 588px;
  height: 100%;

  @media (max-width: ${size.lg}) {
    max-width: 700px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 36px;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 64px;
  row-gap: 64px;

  @media (max-width: ${size.lg}) {
    row-gap: 24px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: column;
  }

  & > * {
    grid-column: span 2;
  }

  & > *:nth-last-child(1):nth-child(odd) {
    grid-column: 3 / span 2;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
