import React from 'react'
import {
  CardContainer,
  Content,
  IdeaImage,
  TextWrapper,
  Wrapper
} from './SpecializationACT.styles'

export const SpecializationACT = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <IdeaImage />
          <TextWrapper>
            Специализация каждого психолога в терапии принятия и
            ответстветственности может немного отличаться, так как все мы разные
            — и это прекрасная возможность найти того человека, с которым работа
            будет комфортной и эффективной именно для вас
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
